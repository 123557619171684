.container {
  width: 100%;
  max-width: 657px;
  border-radius: 22px;
  background: #ffffff;
  box-shadow: 0px 4px 34px 0px #0000001a;
  padding: 20px 30px 30px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
}

.container .id {
  width: 209px;
  height: 26px;
  border-radius: 22px;
  background: #140635;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
  margin-bottom: 15px;
}

.container .id p {
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
}

.container .profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: -95px;
}

.container .profile .icon {
  width: 35px;
  height: 35px;
  position: absolute;
  border-radius: 50%;
  box-shadow: 0px 2.5964913368225098px 22.07017707824707px 0px #0000001a;
}

.container .profile .icon img {
  width: 35px;
  height: 35px;
  border: 0.65px solid #de1468;
  background-color: #ffffff;
  border-radius: 50%;
  padding: 4px;
}

.container .profile .icon input {
  position: absolute;
  width: 25px;
  height: 25px;
  opacity: 0;
  top: 0;
  left: 0;
}

.container .profile .cover {
  width: 100%;
  position: relative;
  height: 199px;
}

.container .profile .cover .img {
  width: 100%;
  height: 199px;
  object-fit: cover;
  border-radius: 22px;
}

.container .profile .cover .icon {
  top: 12px;
  left: 12px;
}

.container .profile .main {
  transform: translateY(-115px);
  width: 173px;
  height: 170px;
  border-radius: 100px;
  background: #ffffff;
  border: 2px solid #ffffff;
  box-shadow: 0px 4px 34px 0px #0000001a;
  position: relative;
}

.container .profile .main .img {
  border-radius: 100px;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.container .profile .main .icon {
  bottom: 0px;
  left: 27px;
}

.container .body {
  width: 100%;
  max-width: 494px;
}

.container .body .name {
  font-size: 24px;
  font-weight: 700;
  line-height: 31px;
  letter-spacing: 0em;
  color: #000000;
  text-align: center;
  margin-bottom: 30px;
}

.container .body .btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 30px;
}

.container .body .btns button {
  width: 48.5%;
  height: 44px;
  border-radius: 22px;
  border: none;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  cursor: pointer;
}

.container .body .btns .daysBtn {
  color: #ffffff;
  background: #270c66;
}

.container .body .btns .servicesBtn {
  color: #ffffff;
  background: #140635;
}

.container .body .check {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 30px;
}

.container .body .check p {
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  color: #000000;
}

.container .body .desc {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 35px;
}

.container .body .desc h4 {
  font-size: 24px;
  font-weight: 700;
  line-height: 31px;
  letter-spacing: 0em;
  color: #0a0a0a;
  margin-bottom: 15px;
}

.container .body .desc textarea {
  width: 100%;
  height: 100px;
  border-radius: 12px;
  background: #ffffff;
  border: 1px solid #de1468;
  resize: none;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0em;
  color: #000000;
  padding: 10px 15px;
  margin-bottom: 8px;
}

.container .body .desc .row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.container .body .desc .row p {
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  color: #0a0a0a;
}

.container .body .form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 25px;
}

.container .body .form .field {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 10px;
  width: 47%;
}

.container .body .form .field label {
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  color: #0a0a0a;
}

.container .body .form .field label span {
  color: #de1468;
}

.container .body .form .field input {
  width: 100%;
  height: 48px;
  border-radius: 12px;
  background: #f9f9f9;
  border: 1px solid #d3d3d3;
  color: #a7a7a7;
  padding: 0px 20px;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0em;
  transition: all 250ms ease-in-out;
}

.container .body .form .field input:focus,
.container .body .form .field input:active {
  background: #ffffff;
  border: 1px solid #de1468;
  box-shadow: 0px 4px 34px 0px #0000001a;
}

.container .body .form .field:nth-child(5),
.container .body .form .field:nth-child(6),
.container .body .form .field:nth-child(7),
.container .body .form .field:nth-child(8) {
  width: 100%;
}

.container .color {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.container .color .title {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 5px;
}

.container .color .title h4 {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: right;
  color: #0a0a0a;
}

.container .color .title span {
  width: 53px;
  height: 17px;
  padding: 2px 14px 3px 14px;
  border-radius: 22px;
  background: #06a816;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  color: #ffffff;
}

.container .color p {
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  color: #000000;
  margin-bottom: 20px;
}

.container .color .colors {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.container .color .colors .circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  opacity: 0.42;
  cursor: pointer;
}

.container .color .colors .active {
  opacity: 1;
  border: 2px solid #de1468;
}

.container .gallery {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.container .gallery .title {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 5px;
}

.container .gallery .title h4 {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: right;
  color: #0a0a0a;
}

.container .gallery .text {
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  color: #000000;
  margin-bottom: 20px;
}

.container .gallery .upload {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 25px;
}

.container .gallery .upload .uploadBtn {
  width: 196px;
  height: 48px;
  border-radius: 22px;
  border: 1px solid #270c66;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
  position: relative;
}

.container .gallery .upload .uploadBtn input {
  position: absolute;
  top: 0;
  left: 0;
  width: 196px;
  height: 48px;
  border-radius: 22px;
  opacity: 0;
  z-index: 9999999;
}

.container .gallery .upload .uploadBtn img {
  width: 24px;
  height: 24px;
}

.container .gallery .upload .uploadBtn p {
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  color: #270c66;
}

.container .gallery .upload .text {
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  color: #0a0a0a;
  margin-bottom: 0px;
}

.container .gallery .images {
  width: 100%;
  padding: 20px 20px;
  background: #f9f9f9;
  border: 1px solid #de1468;
  border-radius: 12px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 18px;
}

.container .gallery .images .image {
  width: 138px;
  height: 138px;
  border-radius: 4px;
  background: #ededed;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container .gallery .images .image .img {
  border-radius: 4px;
  width: 138px;
  height: 138px;
  object-fit: cover;
}

.container .gallery .images .image .minus {
  position: absolute;
  top: -8px;
  right: -8px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #de1414;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: 800;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  padding-bottom: 4px;
  cursor: pointer;
}

.container .submit {
  width: 100%;
  height: 52px;
  border-radius: 22px;
  border: none;
  background: #de1468;
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  color: #ffffff;
  cursor: pointer;
}
.success,
.error {
  width: 100%;
  height: 52px;
  border-radius: 22px;
  border: none;
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0em;
  color: #000;
  cursor: pointer;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.success {
  background: #96ffad;
}

.error {
  background: rgb(255, 141, 141);
}

@media screen and (max-width: 768px) {
  .container .body .check {
    align-items: flex-start;
  }

  .container .body .check p {
    transform: translateY(-3px);
    font-size: 14px;
    line-height: 18px;
  }

  .container .body .form {
    width: 100%;
  }

  .container .body .form .field {
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .container {
    padding: 20px;
  }

  .container .id {
    width: auto;
    height: 26px;
    padding: 0px 20px;
  }

  .container .id p {
    font-size: 14px;
    line-height: 18px;
  }

  .container .profile .main {
    width: 130px;
    height: 130px;
    transform: translateY(-80px);
    margin-bottom: 30px;
  }

  .container .profile .main .icon {
    left: 18px;
  }

  .container .body .name {
    font-size: 20px;
    margin-bottom: 15px;
  }

  .container .body .btns button {
    height: 36px;
    font-size: 14px;
  }

  .container .body .check p {
    font-size: 12px;
    line-height: 15px;
  }

  .container .body .desc h4 {
    font-size: 18px;
    margin-bottom: 8px;
  }

  .container .body .desc textarea {
    font-size: 14px;
    margin-bottom: 4px;
  }

  .container .body .desc .row p {
    font-size: 12px;
  }

  .container .body .form .field label {
    font-size: 16px;
  }

  .container .body .form .field input {
    font-size: 14px;
  }

  .container .gallery .upload {
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }

  .container .gallery .images {
    align-items: center;
    justify-content: center;
  }

  .container .gallery .images .image {
    height: 120px;
    width: 47%;
  }

  .container .gallery .images .image img {
    width: 70px;
    height: 70px;
  }

  .container .submit {
    height: 38px;
    font-size: 14px;
  }
  .success, .error {
    height: 38px;
    font-size: 14px;
  }
  .container .body .gallery .images .image {
    height: 100px;
    width: 47%;
  }
  
  .container .body .gallery .images .image img {
    width: 60px;
    height: 60px;
  }
}

@media screen and (max-width: 767px) {
  .container .body .gallery .images .image {
    height: 100px;
    width: 45%;
  }

  .container .body .gallery .images .image img {
    width: 60px;
    height: 60px;
  }
}
