.container {
  display: flex;
  align-items: center;
  gap: 15px;
  transform: translateY(7px);
  width: 28%;
  position: relative;
}

.container > p {
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0em;
  color: #4f4f4f;
}

.arrow { 
  display: none;
}


@media screen and (max-width: 768px) {
  .container {
    width: 100%;
    transform: unset;
    justify-content: flex-start;
  }

  .container > p {
    font-size: 16px;
    font-weight: 600;
  }

  .arrow {
    display: block;
    position: absolute;
    left: 0;
  }

}
