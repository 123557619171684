/* @import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Hebrew:wght@300;400;500;600;700;800&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Assistant:wght@200;300;400;500;600;700;800&display=swap");

@font-face {
  font-family: "ploni";
  font-weight: 400; /*(regular)*/
  font-style: normal;
  src: url("./fonts/ploni-regular-aaa.woff2") format("woff2"),
    url("./fonts/ploni-regular-aaa.woff") format("woff"),
    url("./fonts/ploni-regular-aaa.eot") format("eot");
}

@font-face {
  font-family: "ploni";
  font-weight: 600; /*(semi-bold)*/
  font-style: normal;
  src: url("./fonts/ploni-demibold-aaa.woff2") format("woff2"),
    url("./fonts/ploni-demibold-aaa.woff") format("woff"),
    url("./fonts/ploni-demibold-aaa.eot") format("eot");
}

@font-face {
  font-family: "ploni";
  font-weight: 700; /*(bold)*/
  font-style: bold;
  src: url("./fonts/ploni-black-aaa.woff2") format("woff2"),
    url("./fonts/ploni-black-aaa.woff") format("woff"),
    url("./fonts/ploni-black-aaa.eot") format("eot");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: 'ploni', sans-serif; */
  font-family: "Assistant", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

html,
body {
  direction: rtl;
}

:root {
  --primary-color: #000;
  --secondary-color: #000;
  --primary-bg-color: #f5f5f5;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
button,
input,
textarea,
select,
label,
small,
li,
span,
table {
  /* font-family: 'ploni', sans-serif !important; */
  font-family: "Assistant", sans-serif !important;
}
button,
input,
select,
textarea {
  outline: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.slick-slider.slick-initialized {
  width: 500px;
  padding: 10px;
}
.slick-slide img {
  width: 100%;
  height: auto;
}

.slick-dots li button:before {
  font-size: 14px;
  color: #999;
}

.slick-dots li.slick-active button:before {
  color: #f9a825;
}

.divider {
  position: absolute; /* Position the divider element relative to its parent */
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #ffbd59; /* Set the background color to #FFBD59 */
}
@media screen and (max-width: 768px) {
  .slick-slider.slick-initialized {
    width: 350px;
  }
}
