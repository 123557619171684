.container {
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.imageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.cover {
  width: 100%;
  height: 199px;
  object-fit: cover;
  border-radius: 22px;
}
.profile {
  box-shadow: 0px 4px 34px 0px #0000001a;
  border: 2px solid #ffffff;
  border-radius: 50%;
  width: 173px;
  height: 170px;
  object-fit: cover;
  transform: translateY(-100px);
  margin-bottom: -75px;
}
.previewHeading {
  color: #000000;
  font-size: 16px;
  line-height: 20.93px;
  margin-bottom: 8px;
}
.previewText {
  font-size: 16px;
  line-height: 20.93px;
  font-weight: 400;
  text-align: center;
  width: 100%;
  max-width: 354px;
  color: #313131;
  margin-bottom: 20px;
}

.socialContainer {
  margin-bottom: 40px;
  width: 100%;
}

.socialContainer .social {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-bottom: 45px;
}

.socialContainer .social a {
  text-decoration: none;
  color: unset;
}

.socialContainer .social .card {
  box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.1);
  width: 173px;
  height: 161px;
  border-radius: 22px;
  transition: all 250ms ease-in-out;
  cursor: pointer;
}

.socialContainer .social .card a {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 22px;
  height: 100%;
  width: 100%;
}

.socialContainer .social .card a svg {
  width: 57px;
  height: 57px;
  transition: all 250ms ease-in-out;
}

.socialContainer .social .card a p {
  transition: all 250ms ease-in-out;
  font-size: 16px;
  line-height: 20.93px;
  color: rgba(0, 0, 0, 1);
  font-weight: 600;
}

.socialContainer .btns {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 100%;
}

.socialContainer .btns button {
  border-radius: 22px;
  width: 100%;
  height: 44px;
  background-color: transparent;
  cursor: pointer;
}

.socialContainer .btns button a {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: unset;
}

.socialContainer .btns button:nth-child(1) {
  max-width: 241px;
}

.socialContainer .btns button:nth-child(2) {
  max-width: 294px;
}

.galleryContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
  width: 100%;
}

.galleryContainer h2 {
  line-height: 41.86px;
  font-size: 32px;
  font-weight: 600;
  color: #0a0a0a;
}

.galleryContainer p {
  line-height: 26.16px;
  font-size: 20px;
  color: #0a0a0a;
  font-weight: 400;
  margin-bottom: 20px;
}

.galleryContainer .images {
  width: 100%;
}

.galleryContainer .images img {
  width: calc(33.333% - 10px);
  min-width: calc(33.333% - 10px);
  height: 177px;
  object-fit: cover;
  margin-right: 10px !important;
}

.galleryContainer .images::-webkit-scrollbar {
  height: 5px;
}

.galleryContainer .images::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.galleryContainer .images::-webkit-scrollbar-thumb {
  background: #888;
}

.galleryContainer .images::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.workingHoursContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.workingHoursContainer h2 {
  line-height: 41.86px;
  font-size: 32px;
  font-weight: 600;
  color: #0a0a0a;
}

.workingHoursContainer > p {
  line-height: 26.16px;
  font-size: 20px;
  color: #0a0a0a;
  font-weight: 400;
  margin-bottom: 20px;
}

.workingHoursContainer > .card {
  height: 100%;
  min-height: 500px;
  width: 100%;
  box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.1);
  background: rgba(255, 255, 255, 1);
  border-radius: 22px;
  padding: 20px 20px 40px 20px;
}

.workingHoursContainer > .card > .days {
  display: flex;
  flex-direction: column;
  margin: 0px 10px;
}
.workingHoursContainer > .card > .days > .day {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgb(220, 220, 220);
  padding: 28px 20px;
}
.workingHoursContainer > .card > .days > .day:last-child {
  border: none;
  padding-bottom: 0px;
}

.workingHoursContainer > .card > .days > .day > .name {
  color: rgb(99, 99, 99);
}

.workingHoursContainer > .card > .days > .day > .timings {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  max-width: 135px;
}
.workingHoursContainer > .card > .days > .day > .timings > p {
  width: 100%;
  color: rgb(99, 99, 99);
}

.workingHoursContainer > .card > .days > .day > .timings > .time {
  display: flex;
  align-items: center;
  gap: 10px;
}

.workingHoursContainer > .card > .days > .day > .timings > .time > p {
  transform: translateY(1.5px);
}

@media screen and (max-width: 768px) {
  .container .imageContainer .cover {
    height: 160px;
  }

  .container .imageContainer .profile {
    width: 140px;
    height: 143px;
    transform: translateY(-90px);
    margin-bottom: -70px;
  }
  .socialContainer .social {
    gap: 10px;
  }

  .socialContainer .btns {
    gap: 10px;
  }

  .galleryContainer .images img {
    width: 33.333%;
  }
  .workingHoursContainer > .card > .days > .day {
    padding: 28px 0px;
  }
}

@media screen and (max-width: 540px) {
  .socialContainer .social .card {
    width: 143px;
    height: 141px;
    padding: 5px;
  }

  .socialContainer .social .card a {
    gap: 16px;
  }

  .socialContainer .social .card a svg {
    width: 40px;
    height: 40px;
  }

  .socialContainer .social .card a p {
    font-size: 15px;
    text-align: center;
    line-height: 18.93px;
  }
}

@media screen and (max-width: 480px) {
  .socialContainer .social {
    margin-bottom: 40px;
  }

  .socialContainer .social .card {
    width: 133px;
    height: 120px;
  }

  .socialContainer .social .card a {
    gap: 14px;
  }

  .socialContainer .social .card a svg {
    width: 38px;
    height: 38px;
  }

  .socialContainer .btns button {
    height: 40px;
  }

  .socialContainer .btns button a p {
    font-size: 16px;
  }

  .socialContainer .btns button a svg {
    width: 20px;
    height: 20px;
  }

  .galleryContainer {
    margin-bottom: 30px;
  }

  .galleryContainer h2 {
    line-height: 38.86px;
    font-size: 26px;
  }

  .galleryContainer p {
    line-height: 24.16px;
    font-size: 18px;
  }

  .galleryContainer .images img {
    height: 140px;
  }

  .workingHoursContainer > h2 {
    line-height: 38.86px;
    font-size: 26px;
  }

  .workingHoursContainer > p {
    line-height: 24.16px;
    font-size: 18px;
  }
}

@media screen and (max-width: 400px) {
  .socialContainer .social .card {
    height: 105px;
  }

  .socialContainer .social .card a {
    gap: 13px;
  }

  .socialContainer .social .card a svg {
    width: 30px;
    height: 30px;
  }

  .socialContainer .btns button {
    height: 40px;
    gap: 8px;
  }

  .socialContainer .btns button a p {
    font-size: 16px;
  }

  .socialContainer .btns button a svg {
    width: 18px;
    height: 18px;
  }

  .galleryContainer h2 {
    line-height: 35.86px;
  }

  .galleryContainer p {
    line-height: 20.16px;
  }

  .workingHoursContainer > h2 {
    line-height: 35.86px;
  }

  .workingHoursContainer > p {
    line-height: 20.16px;
  }

  .galleryContainer .images img {
    height: 110px;
  }
}
