.card {
  width: 100%;
  height: auto;
  min-height: 116px;
  border-radius: 22px;
  padding: 20px 30px;
  display: flex;
  align-items: center;
  box-shadow: 0px 4px 34px 0px #0000001a;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(255, 255, 255, 1);
  cursor: pointer;
  transition: all 300ms ease-in-out;
}
.cardImg {
  width: 80px;
  height: 80px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 15px;
  background-color: #de1468;
}

.cardImg > h3 {
  font-size: 40px;
  font-weight: 600;
  color: rgba(255, 255, 255, 1);
}

.cardContent {
  display: flex;
  flex-direction: column;
  max-width: 60%;
  flex-grow: 1;
}

.cardContent > .name {
  font-weight: 700;
  font-size: 20px;
  line-height: 26.16px;
  color: #0a0a0a;
}

.cardContent > .desc {
  font-size: 15px;
  line-height: 19.62px;
  margin-bottom: 8px;
  color: #000000;
}

.cardContent > .row {
  display: flex;
  align-items: center;
  gap: 16px;
}
.cardContent > .row > .block {
  height: 27px;
  width: auto;
  padding: 0px 10px;
  border-radius: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border: 1px solid #de1468;
  background-color: rgba(222, 20, 104, 0.15);
}
.cardContent > .row > .block > p {
  margin-bottom: -2px;
  font-size: 16px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  color: #d00254;
}

.card > .btn {
  width: 69px;
  height: 71px;
  border-radius: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #140635;
}

/* TABLETS */
@media screen and (max-width: 768px) {
  .card {
    height: auto;
    padding: 20px 15px;
    min-height: 95px;
  }
  .card .img {
    width: 65px;
    height: 65px;
    border-radius: 12px;
    margin-left: 15px;
  }

  .card .img h3 {
    font-size: 30px;
  }


  .cardContent > .desc {
    font-size: 15px;
    line-height: 19px;
    margin-bottom: 7px;
    max-width: 90%;
  }

  .cardContent > .row {
    gap: 10px;
  }
  .cardContent > .row > .block {
    height: 22px;
    gap: 3px;
  }
  .cardContent > .row > .block p {
    font-size: 12px;
    transform: translateY(-1px);
  }

  .cardContent > .row > .block svg {
    width: 11px;
    height: 11px;
    transform: translateY(-0.5px);
  }

  .cardContent > .row > .block:nth-child(1) {
    min-width: 70px;
  }

  .cardContent > .row > .block:nth-child(2) {
    min-width: 80px;
  }

  .card button {
    height: 50px;
    width: 50px;
    border-radius: 10px;
    padding: 0;
    font-size: 12px;
  }
}

@media screen and (max-width: 480px) {
  .cardContent > .row > .block {
    min-width: auto !important;
    padding: 0px 7px;
  }
}

@media screen and (max-width: 400px) {
  .cardContent > .row > .block {
    height: 22px;
  }
  .cardContent > .row > .block p {
    font-size: 10px;
  }

  .cardContent > .row > .block svg {
    width: 10px;
    height: 10px;
  }
}
