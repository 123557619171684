.container {
  display: flex;
  align-items: center;
  gap: 25px;
  width: 30%;
  min-width: 280px;
}
.text {
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  color: #4f4f4f;
  white-space: nowrap;
}

.slots {
  display: flex;
  align-items: center;
  gap: 8px;
}
.slot {
  cursor: pointer;
  width: 67px;
  height: 37px;
  border-radius: 22px;
  background: #f8f8f8;
  border: 1px solid #dcdcdc;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-align: center;
  font-size: 16px;
  font-weight: 500 !important;
  line-height: 21px;
  letter-spacing: 0em;
  color: #767676;
}

.active {
  border: 1px solid #de1468;
  box-shadow: 0px 4px 34px 0px #0000001a;
  color: #de1468;
}

.slots > span {
  color: #de1468;
}


@media screen and (max-width: 768px) {
  .text {
    font-weight: 600;
    font-size: 16px;
  }
}
