.container {
  border-radius: 22px;
  width: 100%;
  max-width: 880px;
  background: #ffffff;
  box-shadow: 0px 4px 34px 0px #0000001a;
  padding: 30px;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.container > h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: center;
  color: #161616;
  width: 100%;
  max-width: 280px;
  margin-bottom: 25px;
}
.text {
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  color: #4f4f4f;
  margin-bottom: 40px;
}

.days {
  display: flex;
  flex-direction: column;
  gap: 18px;
  width: 100%;
  margin-bottom: 30px;
}

.save {
  width: 100%;
  height: 52px;
  border-radius: 22px;
  border: none;
  background: #de1468;
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
}

@media screen and (max-width: 1200px) {
  .container {
    padding: 30px 20px;
  }
}

@media screen and (max-width: 768px) {
  .days {
    gap: 20px;
  }
  .save {
    font-size: 16px;
    text-align: center;
    padding: 5px;
    height: 40px;
    transition: all 0.15s ease-in-out;
    cursor: pointer;
    border: none;
  }
}
