.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.box {
  width: 101px;
  height: 80px;
  border-radius: 12px;
  background: #ffffff;
  border: 1px solid #140635;
  box-shadow: 0px 4px 34px 0px #0000001a;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.box > p {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  color: #0a0a0a;
}

.active {
  border: 1px solid #de1468;
  box-shadow: 0px 4px 34px 0px #de146826;
}

.completed {
  border: 1px solid #de1468;
  box-shadow: 0px 4px 34px 0px #de146826;
  background: rgba(222, 20, 104, 0.1);
}

.active > p,
.completed > p {
  color: #de1468;
}

.line {
  border: 1px solid #0000002e;
  width: 44px;
}
