.container {
  width: 100%;
  min-height: 100vh;
  background-color: #e4e4e442;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 20px 20px 20px;
}
.toggleView {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 20px 20px 20px 20px;
  display: none;
}

.box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 5px;
  cursor: pointer;
}

.box > svg {
  width: 25px;
  height: 25px;
}

.box > p {
  font-size: 14px;
}

.wrapper {
  margin-top: 30px;
  width: 100%;
  max-width: 940px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: #f5f6fa;
  direction: rtl;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 0px;
  width: 50%;
  max-width: 560px;
  height: auto;
}

.verticalLine {
  height: 840px;
  border: 1px solid #d9d9d9;
  margin: 0px 20px;
}

/* ================= PREVIEW STARTS ==================== */

.preview {
  padding: 0px 0px;
  width: 50%;
  max-width: 560px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.previewMainHeading {
  margin-bottom: 15px;
  font-size: 34px;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: 0em;
  text-align: center;
  color: #0a0a0a;
}

.servicesMainHeading {
  margin-bottom: 0px;
  font-size: 30px;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: 0em;
  text-align: center;
  color: #0a0a0a;
}
.servicesMainText {
  margin-bottom: 30px;
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
  color: #0a0a0a;
}

.services {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.card {
  width: 100%;
  height: auto;
  min-height: 116px;
  border-radius: 22px;
  padding: 20px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.1);
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(255, 255, 255, 1);
  cursor: pointer;
  transition: all 300ms ease-in-out;
}
.cardImg {
  width: 80px;
  height: 80px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 15px;
  background-color: #de1468;
}

.cardImg > h3 {
  font-size: 40px;
  font-weight: 600;
  color: rgba(255, 255, 255, 1);
}

.cardContent {
  display: flex;
  flex-direction: column;
  max-width: 60%;
  flex-grow: 1;
}

.cardContent > .name {
  font-weight: 600;
  font-size: 20px;
  line-height: 26.16px;
  color: #0a0a0a;
}

.cardContent > .desc {
  font-size: 15px;
  line-height: 19.62px;
  margin-bottom: 8px;
  color: #000000;
}

.cardContent > .row {
  display: flex;
  align-items: center;
  gap: 10px;
}
.cardContent > .row > .block {
  height: 27px;
  width: auto;
  padding: 0px 10px;
  border-radius: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border: 1px solid #de1468;
  background-color: rgba(222, 20, 104, 0.15);
}
.cardContent > .row > .block > p {
  margin-bottom: -2px;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  color: #d00254;
}

.card > .btn {
  width: 69px;
  height: 71px;
  border-radius: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #140635;
}

/* ================= PREVIEW END ====================== */

.main > h1 {
  font-weight: 900;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  text-align: center;
  width: 100%;
  max-width: 900px;
}

.main > h1 {
  font-size: 44px;
  font-weight: 700;
  letter-spacing: 0.2px;
  margin-bottom: 5px;
}

.main form {
  width: 100%;
  /* max-width: 350px; */
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: white;
  padding: 20px;
}

.main input {
  width: 100%;
  height: 48px;
  padding-right: 16px;
  padding-left: 16px;
  margin-bottom: 2px;
  background-color: #ffffff;
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  color: black;
  font-size: 14px;
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.4s ease-in-out;
  resize: none;
}

.main input:focus {
  border: 1px solid #111827;
}

.main button {
  text-align: center;
  padding: 5px;
  height: 44px;
  background-color: #00a25a;
  color: #fff;
  border-radius: 6px;
  font-size: 24px;
  font-weight: 700;
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  cursor: pointer;
  border: none;
  margin-top: 20px;
}

.main button:hover {
  opacity: 0.8;
}

@media screen and (max-width: 992px) {
  .toggleView {
    display: flex;
  }
  .wrapper {
    justify-content: center;
  }
  .main {
    width: 100%;
  }
  .preview {
    width: 100%;
  }
  .verticalLine {
    display: none;
  }
}

/* TABLETS */
@media screen and (max-width: 768px) {
  .progressContainer {
    padding-bottom: 10px;
  }
  .toggleView {
    padding-bottom: 10px;
  }
  .main {
    justify-content: start;
  }

  .main > h1 {
    font-size: 38px;
  }

  .card {
    height: auto;
    padding: 20px 15px;
    min-height: 95px;
  }
  .card .img {
    width: 65px;
    height: 65px;
    border-radius: 12px;
    margin-left: 15px;
  }

  .card .img h3 {
    font-size: 30px;
  }

  .cardContent > .name {
    font-size: 16px;
    line-height: 22px;
  }

  .cardContent > .desc {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 5px;
    max-width: 90%;
  }

  .cardContent > .row {
    gap: 10px;
  }
  .cardContent > .row > .block {
    height: 22px;
    gap: 3px;
  }
  .cardContent > .row > .block p {
    font-size: 12px;
    transform: translateY(-1px);
  }

  .cardContent > .row > .block svg {
    width: 11px;
    height: 11px;
    transform: translateY(-0.5px);
  }

  .cardContent > .row > .block:nth-child(1) {
    min-width: 70px;
  }

  .cardContent > .row > .block:nth-child(2) {
    min-width: 80px;
  }

  .card button {
    height: 50px;
    width: 50px;
    border-radius: 10px;
    padding: 0;
    font-size: 12px;
  }
}

/* MOBILE */
@media screen and (max-width: 520px) {
  .main {
    padding: 0;
  }

  .main > h1 {
    font-size: 34px;
    line-height: 40px;
  }

  .main form {
    gap: 10px;
  }
  .main input {
    height: 40px;
  }
  .main button {
    font-size: 24px;
  }
}

@media screen and (max-width: 480px) {
  .previewMainHeading {
    margin-bottom: 5px;
    font-size: 24px;
    line-height: 28px;
  }
  
  .servicesMainHeading {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 10px;
  }
  .servicesMainText {
    margin-bottom: 30px;
    font-size: 18px;
  }
  .cardContent > .row > .block {
    min-width: auto !important;
    padding: 0px 7px;
  }
}

@media screen and (max-width: 400px) {
  .cardContent > .row > .block {
    height: 18px;
  }
  .cardContent > .row > .block p {
    font-size: 10px;
  }

  .cardContent > .row > .block svg {
    width: 10px;
    height: 10px;
  }
}
