.container {
  width: 100%;
  min-height: 100vh;
  background-color: #e4e4e442;
  display: flex;
  flex-direction: column;
  align-items: center;
  direction: rtl;
  padding: 70px 20px 20px 20px;
}

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  direction: rtl;
}

.toggleView {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 20px 20px 20px 20px;
  display: none;
  margin-bottom: 20px;
}

.toggleView > .box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 5px;
  cursor: pointer;
}

.toggleView > .box > svg {
  width: 25px;
  height: 25px;
}

.toggleView > .box > p {
  font-size: 14px;
}

.column1 h1,
.column2 h1 {
  margin-bottom: 30px;
  font-size: 38px;
  text-align: center;
}

.row {
  display: flex;
  justify-content: center;
  width: 100%;
}
.verticalLine {
  height: 840px;
  border: 1px solid #d9d9d9;
  margin: 0px 20px;
}

.column1,
.column2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.column1 {
  max-width: 657px;
}
.column2 {
  max-width: 600px;
}

@media screen and (max-width: 992px) {
  .container {
    padding-top: 40px;
  }
  .toggleView {
    display: flex;
  }
  .verticalLine {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .column1 h1,
  .column2 h1 {
    margin-bottom: 25px;
    font-size: 26px;
  }
}
