.container {
  display: flex;
  align-items: baseline;
  gap: 0px;
  padding-bottom: 0px;
}

.column {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 85%;
}
.row {
  display: flex;
  align-items: baseline;
}

@media screen and (max-width: 1080px) {
  .container > .column > .row {
    /* flex-wrap: wrap; */
  }
}

@media screen and (max-width: 768px) {
  .container {
    flex-direction: column;
    align-items: center;
    gap: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.189);
    padding-bottom: 20px;
  }
  .column {
    width: 100%;
    overflow: hidden;
    gap: 18px;
    align-items: center;
    padding: 15px 0px;
  }

  .hide {
    display: none;
  }

  .open {
    display: flex;
  }

  .row {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
}
