.container {
  height: 100%;
  width: 100%;
  max-width: 518px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 20px;
}

.logo {
  margin-bottom: 20px;
  width: 43px;
  height: 39.52px;
}

.container > h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: center;
  color: #0a0a0a;
  margin-bottom: 5px;
}

.mobileText {
  display: none;
}

.container > p {
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  color: #0a0a0a;
  margin-bottom: 25px;
}

.form {
  width: 100%;
  max-width: 351px;
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.field {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.field > label {
  font-size: 16px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  color: #0a0a0a;
}

.field > label > span {
  color: #de1468;
}

.field > input {
  width: 100%;
  height: 48px;
  border-radius: 12px;
  background: #f9f9f9;
  border: 1px solid #d3d3d3;
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  color: #a7a7a7;
  padding: 0px 12px;
  transition: all 250ms ease-in-out;
}

.field > input::placeholder {
  color: #a7a7a7;
}

.field > input:focus {
  border: 1px solid #de1468;
  box-shadow: 0px 4px 34px 0px #0000001a;
  background: #ffffff;
}
.errorMsg {
  color: #de1468;
}

.form > button {
  width: 100%;
  height: 44px;
  border-radius: 22px;
  border: none;
  background: #de1468;
  font-family: Assistant;
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  margin-top: 8px;
}

@media screen and (max-width: 992px) {
  .mobileText {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 25px;
  }

  .mobileText > h2 {
    font-size: 24px;
    font-weight: 700;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    color: #000000;
    margin-bottom: 10px;
  }
  .mobileText > p {
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
    color: #000000;
    width: 100%;
    max-width: 343px;
  }
}

@media screen and (max-width: 400px) {
  .mobileText > p {
    font-size: 14px;
    line-height: 19px;
    max-width: 300px;
  }
}
