.header {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  background-color: #140635;
  height: 90px;
}
.logo > img {
  width: 133px;
  height: 55px;
}
.right > a {
  text-decoration: none;
  color: #1f2937;
  font-size: 16px;
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  display: flex;
  align-items: center;
}
.right > a > img {
  margin-right: 10px;
}

.right > a:hover {
  opacity: 1;
}

/* MOBILE */
@media screen and (max-width: 520px) {
  .logo > img {
  }
  .right > a {
    font-size: 14px;
  }
}
