.slick-next {
  right: 15px !important;
  width: 27px !important;
  height: 27px !important;
}

.slick-prev {
  left: 15px !important;
  width: 27px !important;
  height: 27px !important;
}

@media screen and (max-width: 992px) {
  .slick-next {
    right: -15px !important;
  }

  .slick-prev {
    left: -15px !important;
  }
}

@media screen and (max-width: 480px) {
  .slick-next {
    right: 5px !important;
  }

  .slick-prev {
    left: 5px !important;
  }
}

@media screen and (max-width: 390px) {
  .slick-next {
    right: 15px !important;
  }

  .slick-prev {
    left: 15px !important;
  }
}
