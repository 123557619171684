.container {
  width: 100%;
  max-width: 509px;
  border-radius: 22px;
  box-shadow: 0px 4px 34px 0px #0000001a;
  background: #ffffff;
  padding: 30px;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.container .wrapper {
  width: 100%;
  max-width: 350px;
}

.container .wrapper h2 {
  font-size: 22px;
  font-weight: 700;
  line-height: 31px;
  letter-spacing: 0em;
  color: #0a0a0a;
  text-align: center;
  margin-bottom: 10px;
}

.container .wrapper .mainText {
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  color: #de1468;
  text-align: center;
  margin-bottom: 20px;
  margin-top:-7px;
}

.container .wrapper .types {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 40px;
}

.container .wrapper .types h4 {
  font-size: 24px;
  font-weight: 700;
  line-height: 31px;
  letter-spacing: 0em;
  color: #0a0a0a;
  margin-bottom: 20px;
}

.container .wrapper .types .row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}

.container .wrapper .types .row .type {
  width: 164px;
  height: 213px;
  border-radius: 22px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: #f8f8f8;
}

.container .wrapper .types .row .type svg {
  margin-bottom: 10px;
}

.container .wrapper .types .row .type h5 {
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  margin-bottom: 12px;
  color: #0a0a0a;
}

.container .wrapper .types .row .type button {
  width: 100%;
  max-width: 125px;
  height: 44px;
  border-radius: 22px;
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  cursor: pointer;
  border: 1px solid #270c66;
  background: transparent;
  color: #270c66;
}

.container .wrapper .types .row .type.active {
  background: rgba(255, 172, 205, 0.21);
  border: 1px solid #de1468;
  box-shadow: 0px 4px 34px 0px #0000001a;
}

.container .wrapper .types .row .type.active h5 {
  color: #de1468;
}

.container .wrapper .types .row .type.active button {
  background: #de1468;
  border: 1px solid #de1468;
  color: #ffffff;
}

.container .wrapper .form {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.container .wrapper .form .field {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.container .wrapper .form .field label {
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  color: #0a0a0a;
}

.container .wrapper .form .field label span {
  color: #de1468;
}

.container .wrapper .form .field input {
  width: 100%;
  height: 48px;
  border-radius: 12px;
  background: #f9f9f9;
  border: 1px solid #d3d3d3;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0em;
  color: #afafaf;
  padding: 0px 15px;
  transition: all 250ms ease-in-out;
}

.container .wrapper .form .field input:focus,
.container .wrapper .form .field input:active {
  background: #ffffff;
  border: 1px solid #de1468;
  box-shadow: 0px 4px 34px 0px #0000001a;
}

.container .wrapper .form .field .fixedPrice {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 5px;
}

.container .wrapper .form .field .fixedPrice select,
.container .wrapper .form .field .fixedPrice input {
  width: 100%;
  max-width: 152px;
  height: 62px;
  border-radius: 22px;
  background: rgba(255, 172, 205, 0.21);
  border: 1px solid #de1468;
  box-shadow: 0px 4px 34px 0px #0000001a;
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0em;
  color: #de1468;
  padding: 0px 15px;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: left 0.8rem center;
  background-size: 1em;
}

.container .wrapper .form .field .fixedPrice input {
  padding: 0px 15px;
  width: 100%;
  max-width: 134px;
  height: 62px;
  border-radius: 22px;
  background: #ffffff;
  border: 1px solid #d3d3d3;
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0em;
  color: #2c2c2c;
}

.container .wrapper .form .check {
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 5px 0px;
}

.container .wrapper .form .check p {
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: right;
  color: #0a0a0a;
}

.container .wrapper .form .numberOfCustomers {
  margin-bottom: 5px;
}

.container .wrapper .form .numberOfCustomers h4 {
  width: 100%;
  max-width: 281px;
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  color: #0a0a0a;
  margin-bottom: 15px;
}

.container .wrapper .form .numberOfCustomers h4 span {
  color: #de1468;
}

.container .wrapper .form .numberOfCustomers .counter {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  width: 142px;
  height: 58px;
  border-radius: 12px;
  background: #f9f9f9;
  border: 1px solid #d3d3d3;
  padding: 15px;
}

.container .wrapper .form .numberOfCustomers .counter span {
  width: 27px;
  height: 27px;
  min-height: 27px;
  min-width: 27px;
  border: 1px solid #270c66;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0em;
  color: #270c66;
  cursor: pointer;
}

.container .wrapper .form .numberOfCustomers .counter span:nth-child(3) {
  padding-bottom: 5px;
}

.container .wrapper .form .numberOfCustomers input {
  background-color: transparent;
  border: none;
  width: 50px;
  font-size: 32px;
  font-weight: 600;
  line-height: 42px;
  letter-spacing: 0em;
  text-align: center;
  color: #de1468;
}

.container .wrapper .form .submitBtn {
  width: 100%;
  height: 52px;
  border-radius: 22px;
  border: none;
  background: #de1468;
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  color: #ffffff;
}

.container .wrapper .form .submitBtn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

@media screen and (max-width: 480px) {
  .container {
    padding: 20px;
  }

  .container .wrapper h2 {
    font-size: 18px;
    margin-bottom: 2px;
  }

  .container .wrapper .mainText {
    font-size: 16px;
    margin-bottom: 15px;
    color: #de1468;
  }

  .container .wrapper .types h4 {
    font-size: 20px;
  }

  .container .wrapper .types .row .type {
    padding: 0px 10px;
    width: 48%;
  }

  .container .wrapper .types .row .type h5 {
    font-size: 18px;
  }
}

@media screen and (max-width: 400px) {
  .container .wrapper h2 {
    font-size: 16px;
    margin-bottom: 2px;
  }

  .container .wrapper .mainText {
    font-size: 14px;
    margin-bottom: 15px;
    
  }

  .container .wrapper .types h4 {
    font-size: 20px;
  }

  .container .wrapper .types .row .type {
    padding: 15px 10px;
    height: auto;
  }

  .container .wrapper .types .row .type svg {
    transform: scale(0.8);
    margin-bottom: 5px;
  }

  .container .wrapper .types .row .type h5 {
    font-size: 16px;
    margin-bottom: 8px;
  }

  .container .wrapper .types .row .type button {
    height: 36px;
    font-size: 14px;
    line-height: 19px;
  }

  .container .wrapper .form .field label {
    font-size: 16px;
  }

  .container .wrapper .form .field input {
    font-size: 14px;
  }

  .container .wrapper .form .field .fixedPrice select,
  .container .wrapper .form .field .fixedPrice input {
    width: 50%;
    font-size: 16px;
    height: 50px;
    border-radius: 12px;
  }

  .container .wrapper .form .check p {
    font-size: 14px;
  }

  .container .wrapper .form .numberOfCustomers h4 {
    font-size: 16px;
    line-height: 22px;
  }

  .container .wrapper .form .submitBtn {
    height: 40px;
    font-size: 16px;
  }
}
