.container {
  width: 100%;
  min-height: 100vh;
  /* background-image: url("https://tor.digital/wp-content/themes/hello-elementor/hmassets/img/Hero-bg.svg"); */
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  background-color: #f5f6fa;
  padding: 60px 20px 20px 20px;
}
.wrapper {
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f5f6fa;
}

.main {
  margin-top: 45px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  width: 100%;
  max-width: 500px;
  height: auto;
  background-color: #ffffff;
  box-shadow: 0px 4px 34px 0px #0000001a;
  border-radius: 22px;
}

.main > h1,
h3 {
  font-weight: 900;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  text-align: center;
  width: 100%;
  max-width: 900px;
}

.heading {
  font-size: 24px;
  font-weight: 700;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: center;
  color: #0a0a0a;
}

.text {
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  color: #0a0a0a;
  margin-bottom: 35px;
}

.main > h3 {
  margin-bottom: 28px;
  font-size: 16px;
  color: #000;
  font-weight: 400;
}

.main > .img {
  margin-bottom: 20px;
}

.services {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 700px;
  gap: 20px;
  margin-bottom: 30px;
}

.addService,
.next {
  width: 100%;
  max-width: 700px;
  text-align: center;
  height: 52px;
  background-color: #de1468;
  color: #fff;
  border-radius: 22px;
  font-size: 20px;
  font-weight: 700;
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  cursor: pointer;
  border: none;
}

.addService {
  background-color: #ffffff;
  border: 2px solid #de1468;
  color: #de1468;
  margin-bottom: 20px;
}

.next {
  margin-bottom: 10px;
}

.addService:hover,
.next:hover {
  opacity: 0.8;
}

/* TABLETS */
@media screen and (max-width: 768px) {
  .main {
    padding: 20px;
    justify-content: flex-start;
  }

  .main > .img {
    margin-bottom: 0px;
  }

  .main > h3 {
    font-size: 20px;
  }
  .services {
    margin-bottom: 20px;
    gap: 10px;
  }
}

/* MOBILE */
@media screen and (max-width: 520px) {
  .container {
    padding: 60px 15px 40px 15px;
  }

  .main {
    padding: 20px;
    gap: 5px;
  }

  .text {
    margin-bottom: 25px;
  }

  .main > h3 {
    font-size: 18px;
    width: 100%;
    max-width: 300px;
  }
  .card {
    border-radius: 5px;
    padding: 20px 10px;
  }
  .row {
    flex-direction: column;
    margin-bottom: 0px;
    gap: 0px;
  }
  .addService,
  .next {
  }
  .addService {
    margin-bottom: 15px;
  }
}
