.mainContainer {
  height: 100vh;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999;
}
.container {
  width: 100%;
  /*  height: 100%; */
  max-width: 652px;
  max-height: 500px;
  overflow-y: auto;
  background: #ffffff;
  border-radius: 10px;
  padding: 20px 20px;
  margin: 0px 10px;
  box-shadow: 0 0 0 50vmax rgba(0, 0, 0, 0.5);
  position: relative;
  display: flex;
  flex-direction: column;
}
.close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 35px;
  cursor: pointer;
  background-color: #DE1468;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  cursor: pointer;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
}
.header h2 {
  margin-bottom: 0px;
  color: var(--grey-2);
}
.header span {
  cursor: pointer;
  font-size: 30px;
  color: #555555;
}
.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
