.container {
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 20px;
  position: relative;
}

.bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-top-left-radius: 22px;
  border-bottom-left-radius: 22px;
}

.heading {
  text-align: center;
  font-size: 32px;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  margin-bottom: 12px;
  z-index: 2;
}
.text {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  width: 100%;
  max-width: 499px;
  margin-bottom: 40px;
  z-index: 2;
}

.features {
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 130px;
  z-index: 2;
}

.features .feature {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
}

.features .feature > img {
  width: 90px;
  height: 90px;
  margin-bottom: -10px;
}

.features .feature > h3 {
  font-size: 28px;
  font-weight: 700;
  line-height: 37px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  margin-bottom: 10px;
}

.features .feature > p {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  width: 100%;
  max-width: 335px;
}

.testimonials {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  z-index: 2;
}

.testimonial {
  width: 324px;
  height: 153px;
  border-radius: 12px;
  background: #ffffff;
  box-shadow: 0px 4px 34px 0px #0000001a;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 10px;
}

.testimonial > img {
  transform: translateY(-43px);
  margin-bottom: 4px;
}

.testimonial .rating {
  transform: translateY(-43px);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  margin-bottom: 6px;
}

.testimonial p {
  transform: translateY(-43px);
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  color: #000000;
  width: 100%;
  max-width: 298px;
  margin-bottom: 8px;
}

.testimonial hr {
  transform: translateY(-43px);
  border: 0.5px solid #270c6627;
  width: 100%;
  margin-bottom: 5px;
}

.testimonial small {
  transform: translateY(-43px);
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  color: #000000;
}

@media screen and (max-width: 992px) {
  .bg {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 22px;
    border-bottom-right-radius: 22px;
  }
  .heading,
  .text {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .features .feature > h3 {
    font-size: 24px;
    line-height: 31.39px;
    margin-bottom: 10px;
  }

  .features .feature > p {
    font-size: 16px;
    line-height: 19.33px;
    max-width: 245px;
  }
}
@media screen and (max-width: 480px) {
  .features .feature > h3 {
    max-width: 250px;
  }
}
