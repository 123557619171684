.container {
  width: 100%;
  min-height: 100vh;
  background-color: #e4e4e442;
  display: flex;
  flex-direction: column;
  align-items: center;
  direction: rtl;
  padding: 60px 20px 20px 20px;
}
/* .progressContainer {
  width: 100%;
  max-width: 560px;
  padding: 40px 20px 20px 20px;
}

.toggleView {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 20px 20px 20px 20px;
  display: none;
}

.toggleView > .box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 5px;
  cursor: pointer;
}

.toggleView > .box > svg {
  width: 25px;
  height: 25px;
}

.toggleView > .box > p {
  font-size: 14px;
} */
.wrapper {
  width: 100%;
  max-width: 1300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  direction: rtl;
}

/* .verticalLine {
  height: 840px;
  border: 1px solid #d9d9d9;
  margin: 0px 20px;
} */

/* ================= PREVIEW STARTS ==================== */

/* .preview {
  padding: 0px 0px;
  width: 50%;
  max-width: 560px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.previewMainHeading {
  margin-bottom: 15px;
  font-size: 34px;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: 0em;
  text-align: center;
  color: #0a0a0a;
}

.scheduleHeading {
  margin-bottom: 0px;
  font-size: 30px;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: 0em;
  text-align: center;
  color: #0a0a0a;
  margin-bottom: 20px;
}

.preview > .card {
  height: 100%;
  min-height: 500px;
  width: 100%;
  box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.1);
  background: rgba(255, 255, 255, 1);
  border-radius: 22px;
  padding: 35px 20px 40px 20px;
}

.preview > .card > .days {
  display: flex;
  flex-direction: column;
  margin: 0px 10px;
}
.preview > .card > .days > .day {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgb(220, 220, 220);
  padding: 28px 20px;
}
.preview > .card > .days > .day:last-child {
  border: none;
  padding-bottom: 0px;
}

.preview > .card > .days > .day > .name {
  color: rgb(99, 99, 99);
}

.preview > .card > .days > .day > .timings {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  max-width: 135px;
}
.preview > .card > .days > .day > .timings > p {
  width: 100%;
  color: rgb(99, 99, 99);
}

.preview > .card > .days > .day > .timings > .time {
  display: flex;
  align-items: center;
  gap: 10px;
}

.preview > .card > .days > .day > .timings > .time > p {
  transform: translateY(1.5px);
} */

/* ================= PREVIEW END ====================== */

.days {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  max-width: 700px;
}

.modalWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 20px 20px 20px;
}

.modalWrapper > h3 {
  font-size: 24px;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: center;
  width: 100%;
  max-width: 500px;
  margin-bottom: 40px;
}

.modalWrapper > .btns {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
}

.moreBtn {
  width: 100%;
  max-width: 700px;
  text-align: center;
  height: 52px;
  border-radius: 22px;
  font-size: 20px;
  font-weight: 600;
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  cursor: pointer;
  border: none;
  background-color: #de1468;
  border: 2px solid #de1468;
  color: #ffffff;
}
.skipText {
  color: #0a0a0a;
  text-align: center;
  font-size: 18px;
  cursor: pointer;
}

@media screen and (max-width: 1100px) {
  .mainWrapper {
    width: 55%;
  }
  .preview {
    width: 45%;
  }
}

@media screen and (max-width: 992px) {
  .container {
    justify-content: flex-start;
  }
  /* .toggleView {
    display: flex;
  } */
  .wrapper {
    justify-content: center;
    flex-direction: column;
    max-width: 750px;
  }
  /* .preview {
    width: 100%;
  }
  .verticalLine {
    display: none;
  } */
}

/* TABLETS */
@media screen and (max-width: 768px) {
  .progressContainer {
    padding-bottom: 10px;
  }
  /* .toggleView {
    padding-bottom: 10px;
  } */
  .main {
    justify-content: flex-start;
  }
  .wrapper {
  }

  .main > h1 {
    font-size: 38px;
  }
}

/* MOBILE */
@media screen and (max-width: 520px) {
  .wrapper {
    justify-content: flex-start;
  }

  .main {
    padding: 20px 5px;
  }

  .main > h1 {
    font-size: 28px;
    line-height: 34px;
  }

  .main form {
    gap: 10px;
  }
  .main input {
    height: 40px;
    line-height: 40px;
  }
  .main button {
    font-size: 15px;
  }
  .save {
    font-size: 16px;
    width: 80%;
    text-align: center;
    padding: 5px;
    height: 40px;
    transition: all 0.15s ease-in-out;
    cursor: pointer;
    border: none;
    margin-top: 20px;
    position: fixed;
    bottom: 10px;
  }

  /* .previewMainHeading {
    margin-bottom: 5px;
    font-size: 24px;
    line-height: 28px;
  }

  .scheduleHeading {
    font-size: 20px;
    line-height: 26px;
  } */
}
