.container {
  width: 18%;
  max-width: 160px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  flex-grow: 1;
  gap: 5px;
}
.block {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  margin-bottom: 5px;
}

.block > p {
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  color: #4f4f4f;
}

@media screen and (max-width: 992px) {
  .block > p {
    font-size: 12px;
    line-height: 14px;
  }
}

@media screen and (max-width: 768px) {
  .container {
    flex-direction: row;
    gap: 10px;
    width: 100%;
    max-width: 100%;
  }
  .block > p {
    font-size: 14px;
    line-height: 17px;
  }
}
