.container {
  width: 100%;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
}
.wrapper {
  padding: 50px 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fafafa;
  direction: rtl;
}

.card {
  width: 100%;
  max-width: 1310px;
  min-height: 769px;
  background: #ffffff;
  box-shadow: 0px 4px 34px 0px #0000001a;
  border-radius: 22px;
  display: flex;
}
.modalWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.modalWrapper > .close {
  position: absolute;
  top: 0px;
  right: 0px;
}
.successText {
  margin-bottom: 30px;
  font-size: 20px;
  color: #000000;
  opacity: 0.7;
}
.otpContainer {
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.otpContainer > .errorMsg {
  margin-top: 10px;
}
.modalWrapper > .btn {
  width: 100%;
  height: 52px;
  border-radius: 22px;
  border: none;
  background: #de1468;
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
}
.errorMsg {
  color: red;
  font-size: 14px;
  position: absolute;
  margin-top: 50px;
  right: 0;
  bottom: -18px;
}
.otp input {
  border: none;
  border-bottom: 1px solid grey;
}

.already {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 10px;
  position: relative;
}

.already > .error {
  position: absolute;
  top: 0px;
  right: 0px;
  font-size: 20px;
}

.already p {
  font-size: 20px;
  line-height: 26px;
  width: 100%;
  max-width: 500px;
  text-align: center;
  margin: 0px;
  margin-bottom: 30px;
}
.store {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

@media screen and (max-width: 992px) {
  .card {
    flex-direction: column;
    align-items: center;
    height: auto;
  }
}

@media screen and (max-width: 520px) {
  .wrapper {
    padding: 80px 15px 15px 15px;
  }
  .modalWrapper > .btn {
    font-size: 16px;
    text-align: center;
    padding: 5px;
    height: 40px;
    transition: all 0.15s ease-in-out;
    cursor: pointer;
    border: none;
  }
  .otp input {
    margin: 0px 0px !important;
    width: 40px !important;
    height: 40px !important;
  }
  .store {
    flex-direction: column;
  }
}
