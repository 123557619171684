.container {
  width: 100%;
  min-height: 100vh;
  /* background-image: url("https://tor.digital/wp-content/themes/hello-elementor/hmassets/img/Hero-bg.svg"); */
  background-repeat: no-repeat;
  background-color: #fafafa;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 20px 20px 20px;
}

.card {
  width: 100%;
  max-width: 500px;
  height: auto;
  border-radius: 22px;
  background: #ffffff;
  box-shadow: 0px 4px 34px 0px #0000001a;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.top {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 55px 20px 30px 20px;
}

.img {
  margin-bottom: 5px;
}
.heading {
  font-size: 32px;
  font-weight: 800;
  line-height: 42px;
  letter-spacing: 0em;
  text-align: center;
  color: #000000;
  margin-bottom: 2px;
}

.text {
  font-size: 24px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #000000;
  margin-bottom: 55px;
}

.textBox {
  width: 100%;
  max-width: 436px;
  height: 210px;
  border-radius: 12px;
  background: rgba(222, 20, 104, 0.04);
  border: 2px dotted #de1468;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 35px;
}

.textBox .textOne {
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  color: #000000;
  text-align: center;
  margin-bottom: 20px;
}

.textBox .textOne span {
  font-weight: 700;
}

.textBox .textTwo {
  font-size: 18px;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
  color: #270c66;
  margin-bottom: 20px;
}

.copy {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.copy p {
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  color: #000000;
}

.copy h5 {
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0em;
  color: #6029e1;
}

.store {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.store h4 {
  font-size: 24px;
  font-weight: 700;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: center;
  color: #000000;
  margin-bottom: 4px;
}

.store p {
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  color: #000000;
  width: 100%;
  max-width: 340px;
  margin-bottom: 20px;
}

.imgs {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}
.store img {
  width: 147px;
  height: 43px;
}

.bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 30px 20px;
  border-top: 1px solid #00000061;
}
.bottom button {
  width: 100%;
  height: 52px;
  border-radius: 22px;
  border: none;
  background: #de1468;
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  color: #ffffff;
}

@media screen and (max-width: 480px) {
  .container {
    padding: 40px 15px 20px 15px;
  }

  .card {
    height: auto;
  }

  .top {
    padding: 40px 20px 20px 20px;
  }

  .text {
    margin-bottom: 40px;
  }

  .textBox {
    height: 204px;
  }

  .textBox .textOne {
    font-size: 14px;
    line-height: 17.54px;
    margin-bottom: 20px;
  }

  .textBox .textOne span {
    font-weight: 700;
  }

  .textBox .textTwo {
    font-size: 14px;
    line-height: 17.54px;
    margin-bottom: 20px;
  }

  .store h4 {
    font-size: 20px;
    line-height: 27px;
    margin-bottom: 2px;
  }

  .store p {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    color: #000000;
    width: 100%;
    max-width: 340px;
    margin-bottom: 20px;
  }

  .imgs {
    gap: 0px;
  }

  .store img {
    transform: scale(0.9);
  }

  .bottom {
    padding: 25px 15px;
  }
  .bottom button {
    font-size: 16px;
    text-align: center;
    padding: 5px;
    height: 40px;
    transition: all 0.15s ease-in-out;
    cursor: pointer;
    border: none;
  }
}
