.container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  width: 30%;
  min-width: 210px;
}

.item {
  border: 1px solid #0000002c;
  border-radius: 5px;
  padding: 7px;
  cursor: pointer;
  min-width: 80px;
}

.active {
  border: 1px solid #de1468;
}

.active p {
  color: #de1468;
}


@media screen and (max-width: 768px) {
  .container {
    width: 100%;
    justify-content: center;
  }
}